@use 'styles/_variables.scss' as v;
@use 'styles/_mixins.scss' as m;

.rechargeModal {
    .modal-body {
        padding: 26px 10px;

        .notice {
            svg {
                path {
                    fill: v.$primaryLightBlue;
                }
            }
        }

        .lowBalanceWrapper {
            margin-bottom: 16px;
            
            h4 {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0px;
            }

            p {
                font-size: 15px;
                margin-bottom: 0px;
                font-weight: 700;
                color: v.$primaryBlue;
            }

            .userBalance {
                p {
                    color: v.$secondaryRed-2;
                }
            }
        }

        .inputGroupCoupon {
            span {
                font-size: 15px;
                font-weight: 500;
                color: v.$secondaryRed;
            }
        }
    }

    .modal-header {
        border: none;
        padding-top: 24px;

        .modal-title {
            display: flex;
            justify-content: space-between;
            text-align: center;
            @include m.TitleSize16withoutResponsive;
        }

        .btn-close {
            margin-left: 0px;
            margin-right: 0px;
            background: url('../../assets/images/svg/CrossSvgPackageModal.svg');
            background-repeat: no-repeat;
            opacity: 1;
        }
    }

    .modal-footer {
        padding: 26px 10px;
        justify-content: center;

        .iconButtonPackageModal,.iconButtonCouponModal {
            border-radius: 30px;
            // width: 165px;
            font-size: 14px;
            height: 48px;
            justify-content: center;
            font-weight: 700;

            &:disabled {
                background-color: v.$primaryLightGray-2;
                border: none;
            }
        }
    }

    .couponFooter {
        justify-content: center;
    }
}

.packageList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    label {
        font-size: 12px;
        font-weight: 500;
        display: grid;
        color: #1b1b1b;
        height: 125px;
        padding: 10px;
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        border: 0.5px solid v.$primaryLightGray-2;
        background: v.$white;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        transition: all .3s ease-in-out;

        &:hover {
            scale: 1.05;
            border: 0.5px solid v.$primaryBlue;
        }

        p {
            margin: 0;
        }

        .pointsHolder {
            position: relative;

            .title {
                gap: 3px;
                font-size: 20px;
                font-weight: 700;
            }

            .credits {
                @include m.TitleSize16;
                color: v.$black;
                margin-top: -8px;
            }

            .walletRecharge {
                padding-top: 5px;
                color: v.$secondaryGray;
            }

            .cashInfo {
                position: absolute;
                bottom: 0px;
                font-size: 14px;
                font-weight: 700;
                gap: 5px;
                color: v.$primaryBlue;
                left: 0px;
            }
        }
    }

    input {
        position: absolute;
        display: none;
    }

    input:checked~label {
        background-color: v.$primaryLightBlue;
        border: 3px solid v.$primaryBlue;
        color: v.$white;

        .pointsHolder {
            .credits {
                color: v.$white-opacity-75;
            }

            .cashInfo {
                color: v.$white;
            }

            .walletRecharge {
                padding-top: 5px;
                color: v.$white;
            }
        }
    }
}

@media (min-width: 991px) {
    .rechargeModal {
        .modal-body {
            padding: 1rem 4rem;
        }

        .modal-footer {
            padding: 1rem 4rem;

            p {
                max-width: 70%;
                text-align: start;
            }

            justify-content: space-between;
        }

        .couponFooter {
            justify-content: center;
        }
    }

    .packageList {
        gap: 15px 16px;

        label {
            .pointsHolder {

                .cashInfo {
                    position: absolute;
                    bottom: 0px;
                    left: unset;
                    right: 0px;
                }
            }

        }
    }
}

@media (min-width: 768px) {
    .packageList {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 425px) {
    .rechargeModal {
        .modal-dialog {
            position: fixed;
            bottom: 0;
            transform: translate(-50%);
            margin: 0px;
            width: 100%;
            align-items: flex-end !important;
        }

        .modal-body {
            padding-bottom: 10px;
            padding-top: 0px;
        }

        .modal-footer {
            padding: 0px 10px 10px 0px !important;
        }
    }

    .packageList {
        padding: 6px;

        label {
            height: max-content;
            min-height: 133px;
            padding: 8px;

            .pointsHolder {
                .title {
                    font-size: 18px;
                }

                .cashInfo {
                    font-size: 10px;
                }
            }
        }
    }
}