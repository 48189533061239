@use 'styles/_variables.scss' as v;
@use 'styles/_mixins.scss' as m;

.consultantCallModal {
  .modal-content {
    border-radius: 10px !important;
    width: auto;
  }
}

.callingModalWrapper.consultantCallingModalWrapper {
  position: relative;
  right: unset;
}

.callingModalWrapper {
  width: 312px;
  position: absolute;
  right: 15%;
  background-color: v.$white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid v.$primaryBlue;
  color: v.$primaryBlue;
  z-index: 10;

  p {
    margin-bottom: 0px;
  }
}

.__mainContent {
  padding: 30px 40px 20px 40px;
  p {
    text-align: center;
    margin-bottom: 0px;
  }
}

.__desp {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.__userInfoContainer.consultant__userInfoContainer {
  flex-direction: row-reverse;
}

.__userInfoContainer {
  margin-top: 20px;
  display: flex;
  position: relative;
  justify-content: center;

  .dottedLineSvg {
    margin-top: 35px;
  }

  .__type {
    position: absolute;
    z-index: 11;
    background-color: v.$white;
    top: 17px;
  }

  .__backLine {
    width: 100%;
    background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
  }

  .__imgs {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    object-fit: cover;
  }

  p {
    margin-top: 10px;
    word-wrap: break-word;
    font-size: 14px;
    width: 75px;
    text-align: center;
    max-width: 75px;
    @include m.textEllipsis_2Line
  }
}

.__footer {
  padding: 0px 20px 20px 20px;

  .__description {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 5px;
    background-color: v.$primaryWhite-opacity-7;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    text-align: center;
    padding: 0px 10px;

    p {
      color: v.$secondaryGray;
      padding: 12px 5px;
    }

  }

  .btnSendConsult {
    margin-top: 15px;
    width: 100%;
    border-radius: 30px;
    justify-content: center;
  }
}

.callModalMobile.consultantCallModalMobile  {
  .modal-dialog {
    position: fixed;
    bottom: 0;
    margin: 0px;
    width: 100%;
    align-items: flex-end !important;
  }

  .modal-header {
    justify-content: center;
  }

  .__mainContent {
    padding: 0px;
  }

  p {
    margin-bottom: 0px;
    word-wrap: break-word;
  }

  .modal-footer {
    .__footer {
      width: 100%;
    }
  }
}

.callModalMobile {
  color: v.$primaryBlue;


  .__userInfoContainer {
    .vstack {
      flex: none;
    }
  }

  .modal-footer {
    border: none;
    padding: 0px;
  }
}

@media (min-width: 991px) {
  .callingModalWrapper {
    top: 10px;
  }

  .callingModalWrapper.consultantCallingModalWrapper {
    top: 0px;
  }
}

@media (max-width: 991px) {
  .callingModalWrapper {
    top: 40px;
  }

  .callingModalWrapper.consultantCallingModalWrapper {
    top: 0px;
  }
}

@media (max-width: 576px) {
  .callingModalWrapper.consultantCallingModalWrapper {
    top: 0px;
  }

  .consultantCallModal {
    .modal-dialog-centered {
      justify-content: center;
    }

    .modal-content {
      border-radius: 10px;
    }
  }
}

@media (max-width: 425px) {
  .__footer {
    padding: 0px 20px 10px 20px;
  }

  .__mainContent {
    padding: 30px 30px 00px 30px;
  }

  .consultantCallModalMobile {}
}

[dir='rtl'] {
  .callingModalWrapper {
    left: 15%;
    right: unset;

    .closeButton {
      right: unset;
      left: 12px;
    }
  }
}