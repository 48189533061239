@use 'styles/_variables.scss' as v;

$primary-color: #1b75bc;

.bg-image {
    img {
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
    }
}

.header {
    position: fixed;
    width: 100%;
    z-index: 100;

    .langMobile {
        display: flex;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5rem;
            color: v.$secondaryGray;
        }
    }

    .langDesktop {
        display: none;
    }

    .header__spanLinks {
        width: 100%;
        height: 28px;
        display: flex;
        background-color: #ED943B;
        z-index: 1032;
        position: fixed;
        align-items: center;
        justify-content: center;

        p {
            a {
                text-decoration: none;
            }

            font-size: 16px;
            color: #fff;
            margin: 0px;
        }
    }

    .navbar-brand a {
        color: var(--bs-navbar-brand-color);
    }

    #lang-nav-dropdown {
        color: var(--bs-navbar-brand-color-3);
    }

    .langDesktop a {
        color: var(--bs-navbar-brand-color-2);
    }

    .langMobile a {
        color: var(--bs-navbar-brand-color-2);
    }


    .navbar {
        height: 75px;
        // padding-top: 2rem;
        --bs-navbar-brand-color-3: #6D6E70;
        --bs-navbar-brand-color-2: #35405a;
        --bs-navbar-color: #35405a;
        --bs-navbar-hover-color: #1b75bc;
        --bs-navbar-active-color: #1b75bc;
        --bs-navbar-brand-color: #1b75bc;
        --bs-navbar-brand-hover-color: #1b75bc;
        --bs-navbar-toggler-icon-bg: url("../../../assets/images/svg/nav_hamBurger.svg");
        background: #fff;

        .navbar-nav {
            --bs-nav-link-color: #000;
            color: #000;
        }

        .navbar-brand {
            display: flex;
            gap: 1rem;
            margin-right: 0px;
        }

        .nav-link {
            position: relative;
            font-weight: 500;
        }
    }

    .mainNav-bar {
        --bs-nav-link-padding-y: 0.7rem;
        --bs-navbar-nav-link-padding-x: 1.05rem;
    }

    .dropdown-toggle::after {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.40156 1.45898L6.8349 6.89232C7.47656 7.53398 8.52656 7.53398 9.16823 6.89232L14.6016 1.45898' stroke='%2335405a' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        border: 0;
        width: 12px;
        height: 7px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        vertical-align: baseline;
        margin-left: 0.5rem;
    }

    .dropdown-menu {
        border: 0;
        --bs-dropdown-min-width: 200px;
        --bs-dropdown-border-radius: 0;
        max-width: 200px;
        box-shadow: 0 23px 25px #0000001a;
        --bs-dropdown-font-size: 14px;
    }

    .dropdown-item {
        white-space: normal;
        --bs-dropdown-item-padding-y: 0.65rem;
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: v.$primaryLightBlue-2;
    }

    .navbar-toggler {
        border: 0;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-toggler-icon {
        width: 1em;
        height: 1em;
    }

    .langDropdown .dropdown-menu {
        --bs-dropdown-min-width: 70px;
    }
}

.sideNavBar__left {
    a {
        font-weight: 500;
    }
}

.blueForeground {
    .nav__userInfoWrapper {
        border: 2px solid v.$primaryBlue;
    }
}

.sideNavBar {

    .sidebar__blue {
        .nav__userInfoWrapper {
            border-color: v.$primaryBlue !important;

            span {
                color: v.$primaryBlue;
            }
        }
    }

    .lang-nav-dropdown__userLink {
        .dropdown-menu {
            min-width: 165px;
            width: 165px;
            
            a {
                display: flex;
                gap: 15px;
                padding: .8rem 1.5rem;
                position: relative;
                width: 100%;
                align-items: center;
                text-transform: capitalize;
                
                .unreadNotfs {
                    position: absolute;
                    text-align: center;
                    left:17px;
                    top: 5px;
                    font-size: 10px;
                    color: v.$white;
                    padding: 1px 6px;
                    background: v.$secondaryRed;
                    border-radius: 10px;
                }
            }
        }
    }

    .userInfoDropdown {
        a {
            &::after {
                display: none;
            }
        }

        .nav__userInfoWrapper {
            height: 35px;
            padding: 6px 10px 6px 5px;
            display: flex;
            overflow: hidden;
            justify-content: space-around;
            align-items: center;
            border-radius: 30px;
            gap: 8px;
            max-width: 230px;
            overflow: hidden;

            img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }

            span {
                font-size: 16px;
                font-weight: 500;
                width: auto;
                max-width: 120px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .sidebar__blue {
                color: v.$primaryBlue !important;
            }
        }

        .dropdown-menu {
            border-radius: 20px;
            overflow: hidden;
        }
    }

    .inbox__link {
        color: v.$white !important;
        background-color: v.$primaryBlue !important;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .balance__link {
        cursor: pointer;
        margin-left: 8px;
        width: 45px;
        height: 45px;
        background-color: v.$white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: v.$primaryBlue;
        border: 2px solid v.$primaryBlue;

        span {
            font-weight: 700;
            font-size: 12px;
        }
    }
}

.sideNavBar .navbar__mobile {
    --bs-nav-link-padding-y: 0.3rem;

    .nav-link:hover {
        background: #1b75bc2e;
        border-radius: 0.375rem;
    }

    .btn-warning:hover {
        background: #c78552;
        color: #fff;
    }

    .menuLink {
        padding: 6px 0.5rem;
        margin: 0 0.25rem;
        position: relative;
        color: #1b75bc;
        text-decoration: none;
        display: flex;
        align-items: center;
        border-radius: 5px;
        min-height: 37px;
        cursor: pointer;

        .countNotification {
            background-color: #eca066;
            color: #fff;
            position: absolute;
            z-index: 1;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -1px;
            left: -4px;
            font-size: 12px;
            border-radius: 8px;
            padding: 0 0.5em;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: #1b75bc2e;
        }
    }
}

.navbarOffcanvas.offcanvas {
    --bs-offcanvas-width: 270px;
    --bs-offcanvas-padding-x: 0;
    color: #fff;

    .offcanvas-header {
        --bs-offcanvas-padding-x: 1.25rem;

        .btn-close {
            opacity: 1;
            margin-left: auto;
        }
    }

    .navbar-nav {
        --bs-nav-link-padding-x: 1.5rem;
    }

    .dropdown-menu {
        border: 0;
        --bs-dropdown-border-radius: 0;
        --bs-dropdown-font-size: 14px;
        --bs-dropdown-item-padding-x: 1.5rem;
    }
}

.header.white-menu {
    .navbar {
        --bs-navbar-brand-color-3: #fff;
        --bs-navbar-brand-color-2: #1b75bc;
        --bs-navbar-brand-color: #fff;
        --bs-navbar-brand-hover-color: #fff;
        --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 129' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        background: #1b75bc;
        position: absolute;

        .navbar-nav {
            --bs-nav-link-color: #fff;
            color: #fff;
        }
    }
}

.header.white-menu.gradientMenu {
    .navbar {
        background: linear-gradient(90deg, #1b75bc 45%, #00aeef 100%);
        // height: 72px;
    }
}

[dir="rtl"] {
    .header {
        .dropdown-toggle::after {
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }
}

@media (min-width: 992px) {
    .navbar__mobile {
        display: none;
    }

    .header {

        .langMobile {
            display: none;
        }

        .langDesktop {
            display: flex;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .5rem;
            }
        }

        .mainNav-bar .nav-link::before {
            content: "";
            width: 0;
            height: 3px;
            position: absolute;
            bottom: 0;
            right: 50%;
            background-color: $primary-color;
            border-radius: 30px;
            transform: translateX(50%);
            transition: all .2s ease-in-out;
        }

        .mainNav-bar .show>.nav-link:before,
        .mainNav-bar .nav-link:hover::before,
        .mainNav-bar .nav-link.active::before {
            width: 60%;
        }
    }

    .header.white-menu {
        .navbar {
            --bs-navbar-color: #fff;
            --bs-navbar-hover-color: #fff;
            --bs-navbar-active-color: #fff;

            &:before {
                background: none;
                width: 0;
            }

            &:after {
                background: none;
                width: 0;
            }
        }

        .sideNavBar {
            display: flex;
            justify-content: center;
            align-items: center;

            .nav-link:hover {
                // background: #ffffff2e;
            }

            .btn-warning:hover {
                background: #c78552;
                border-color: #c78552;
            }

            .menuLink {
                color: #fff;

                &:hover {
                    background: #ffffff2e;
                }
            }
        }

        .mainNav-bar .nav-link::before {
            background-color: #fff;
        }

        .dropdown-toggle::after {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.40156 1.45898L6.8349 6.89232C7.47656 7.53398 8.52656 7.53398 9.16823 6.89232L14.6016 1.45898' stroke='%23ffffff' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
    }
}

@media (max-width: 991px) {

    .sideNavBar {
        .loginButtonLink {
            // width: 130px !important;
        }
    }

    .navbar .container {
        flex-direction: row-reverse;
    }

    .sideNavBar__left {
        display: none !important;

        svg {
            display: none !important;
        }
    }

    .userInfoDropdown {
        display: none !important;
    }

    .balance__link {
        display: none !important;
    }

    .navbarOffcanvas.offcanvas {
        .navbar-nav {
            font-size: 14px;
            color: #000;

            .nav-link {
                margin-bottom: 2px;
                border-bottom: 1px solid #00000017;
            }

            &.sideNavBar {
                display: flex;
                flex-direction: row;
                --bs-nav-link-padding-x: 0.5rem !important;
                text-align: center;
                height: 40px;

                .btn {
                    --bs-btn-border-radius: 0;
                }

                .langDropdown {
                    justify-content: start;
                }

                .menuLink {
                    border-bottom: 1px solid #00000017;
                    margin: 0;
                    border-radius: 0;
                    color: inherit;
                    display: flex;
                    gap: 10px;
                }

                &>* {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
            }

            .langDropdown {
                .dropdown-menu {
                    position: absolute;
                    border-bottom: 0;
                    --bs-dropdown-item-padding-y: 0.5rem;
                }

                .dropdown-toggle {
                    height: 100%;
                    align-items: center;
                    display: flex;
                }
            }
        }

        .dropdown-menu {
            border-bottom: 1px solid #00000017;
        }
    }

    .header .navbar {
        height: auto;
        box-shadow: 0 6px 5px 0 rgb(0 0 0 / 3%);
    }

    .header.white-menu {
        .navbar {
            background: linear-gradient(132deg, $primary-color 43%, #00aeef 100%);
        }
    }
}

@media (max-width: 750px) {
    .header__spanLinks {
        height: 28px !important;

        p {
            font-size: 13px !important;
        }
    }
}

@media (max-width: 500px) {
    .header__spanLinks {
        p {
            font-size: 9px !important;
        }
    }
}

@media (max-width: 350px) {
    .header__spanLinks {
        p {
            font-size: 8px !important;
        }
    }
}

@media (max-width: 300px) {
    .header__spanLinks {
        p {
            font-size: 7px !important;
        }
    }
}