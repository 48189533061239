$primaryBlue: #1B75BB;
$primaryBlue-opacity-2: #1B75BB20;
$primaryBlue-opacity-5: #1B75BB50;
$primaryLightBlue: #00ADEE;
$primaryBlue-opacity-1: #00ADEE10;
$primaryLightBlue-2: #40C2F2;
$primaryLightBlue-2-opacity-2: #40C2F220;
$primaryLightBlue-3: #0290FF;
$secondaryBlue: #2457B9;

$white: #FFF;
$white-opacity-75: #ffffff75;
$white-opacity-2: #ffffff20;
$black: #000;
$black-opacity-1: #00000010;
$primaryWhite: #F7F7F8;
$primaryWhite-1: #F0F0F1;
$primaryWhite-2: #F4F5F6;
$primaryWhite-opacity-7: #F7F7F870;
$primaryHoverBlue-opacity-2: #1b75bc2e;
$primaryOrange: #ED943B;
$primaryOrange-opacity-2: #ED943B20;
$secondaryOrange: #FEBA0F;
$primaryLightGray: #BFD2E1;
$primaryLightGray-1: #A6A8AB40;
$primaryLightGray-2: #A6A8AB;
$primaryLightGray-3-opacity-1: #A6A8AB10;
$primaryLightGray-3-opacity-2: #A6A8AB20;
$primaryLightGray-3-opacity-5: #A6A8AB50;

$primaryLightGray-4: #D9D9D9;
$dividerColor: #ECECEC;

$secondaryGray: #6D6E70;
$secondaryGray-opacity-2: #6D6E7020;
$secondaryGray-opacity-8: #6D6E7080;
$secondaryGray-opacity-05: #6D6E7005;
$primaryDrakPink: #A155A5;
$primaryDrakPink-opacity-4: #A155A540;
$primaryDrakPink-opacity-8: #A155A580;
$primaryLightGreen: #69C677;
$primaryLightGreen-1: #E1F3E3;
$primaryLightGreen-2: #F0F9F1;
$primarySkinColor: #C4A484;
$other_1: #C5A919;
$secondaryRed: #F27068;
$secondaryRed-2: #D33;
$secondaryRed-opacity-2: #F2706820;
$primaryRed: #FCE2E1;

$commonBoxShadowCard: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);