@use 'styles/_variables.scss' as v;
$dark: #35405a;
.main {
    // padding: 70px 0 0;
    min-height: 100vh;
}
@media (min-width: 992px) {
    .main {
        // padding: 100px 0;
    }
}
.titleHeadHome {
    text-align: center;
    font-weight: 500;
    margin-bottom: 3rem;
}
.get-started {
    display: flex;
    padding: 80px 0px;
    flex-direction: column;
    //align-items: flex-start;
    gap: 20px;
    background: linear-gradient(268deg, #1B75BB 0%, rgba(0, 173, 238, 0.00) 100.5%);
    h2 {
        color: #1B75BB;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
    }
    .cards{
        min-height: 138px !important;
        min-width: 300px !important;
    }
    @media (max-width: 767px) {
        padding: 60px 0;
    }
}
.heroBanner__ImgWrapper {
    img {
        object-fit: contain;
        width: 100%;
        height: auto;
    }
    &::after {
        content: '';
        background: url('https://faserly-assets.s3.amazonaws.com/assets/app/media/img/web/other/boxes_2__mobile.webp');
        background-repeat: no-repeat;
        position: absolute;
        left: 0px;
        bottom: -14%;
        width: 67%;
        height: 140px;
        z-index: -1;
        @media (min-width: 425px) {
            display: none;
        }
    }
}
.heroBanner {
    color: $dark;
    padding: 120px 0px 50px 0px;
    .heroTitle {
        color: v.$primaryBlue;
        font-size: 38px;
        line-height: 45px;
        font-weight: 800;
    }
    .statisticsWrapper {
        display: flex;
        gap: 17px;
        flex-wrap: wrap;
        margin-top: 20px;
        .divider {
            width: 1px;
            height: 50px;
            background-color: v.$black;
        }
        .item {
            text-align: center;
            line-height: 10px;
            h6 {
                font-weight: 700;
            }
            p {
                font-weight: 500;
                font-size: 14px;
                margin: 0px;
            }
        }
    }
    .heroContent {
        background-color: v.$primaryLightBlue;
        font-weight: 500;
        font-size: 16px;
        padding: 12px 15px;
        border-radius: 40px;
        justify-content: start;
        margin-top: 20px;
        align-items: center;
        border-radius: 35px;
        height: 36px;
        border: 1px solid v.$primaryLightBlue-3;
        svg path {
            fill: v.$white ;
        }
    }
    .main-lead{
        font-size:1em;
        font-weight: 300;
    }
}
.availablePackagesSect {
    padding: 40px 0;
}
.titlehead {
    h2 {
        margin-bottom: 1rem;
    }
}
.section {
    padding: 80px 0;
}
.app-detail {
    height: 320px;
    position: relative;
    // padding-bottom: 65px;
    .app-detail-row {
        height: 154px;
    }
    .app-detail-col1 {
        &::before {
            content: '';
            background: url('https://faserly-assets.s3.amazonaws.com/assets/app/media/img/web/other/boxes_2.webp');
            background-repeat: no-repeat;
            position: absolute;
            left: 0px;
            top: 80px;
            width: 30%;
            height: 154px;
            z-index: -1;
        }
    }
    .app-detail-col {
        width: 70%;
        margin-top: 100px;
        @media (max-width: 425px) {
            margin-top: 75px;
        }
        .app-detail-mainText {
            color: #1B75BB;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            @media (max-width: 425px) {
                font-size: 18px;
            }
        }
        .app-detail-leadText {
            color: #212529;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media (max-width: 425px) {
                font-size: 14px;
            }
        }
    }
}
;
@media (min-width: 768px) {
    .aboutFaserly {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: -15px;
            bottom: 0;
            background: url(../../assets/images/bg-login.png);
            background-repeat: no-repeat;
            background-size: auto;
            width: 130px;
            height: 240px;
            z-index: -1;
        }
    }
}
@media (min-width: 992px) {
    .heroBanner {
        .heroTitle {
            font-size: 60px;
            line-height: 70px;
        }
    }
    .availablePackagesSect {
        padding: 110px 0;
    }
    .titlehead {
        font-size: 18px;
    }
}
.aboutContainer {
    background: url('https://faserly-app.s3.amazonaws.com/aboutImgs/about_bg.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
@media (max-width: 768px) {
    .heroBanner__headingsWrapper {
        margin-bottom: 2rem;
    }
    .heroBanner__ImgWrapper {
        margin-top: 3rem;
    }
}
@media (max-width: 500px) {
    .heroBanner .statisticsWrapper {
        gap: 10px;
        .divider {
            height: 65px;
        }
        .item {
            width: 28%;
            h6 {
                font-weight: 700;
            }
            p {
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
}
@media (max-width: 425px) {
    .aboutContainer {
        background: url('https://faserly-app.s3.amazonaws.com/aboutImgs/about_bg_mob.webp');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .heroBanner {
        .heroContent {
            margin-top: 20px;
            justify-content: center;
            background-color: v.$primaryLightBlue;
            align-items: center;
            font-size: 12px;
            &:hover {
                background-color: v.$primaryLightBlue-2;
            }
        }
    }
}
[dir="rtl"] {
    @media (min-width: 992px) {
        .heroBanner {
            .heroTitle {
                line-height: 75px;
            }
        }
    }
    @media (max-width: 991px) {
        .aboutContainer {
            margin-top: 5rem;
        }
        .heroBanner {
            .heroTitle {
                line-height: 50px;
            }
        }
    }
}