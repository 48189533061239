@use 'styles/_variables.scss' as v;
@use 'styles/_mixins.scss' as m;

.exclamationNotice {
  max-width: 100%;
  margin-bottom: 10px;
  text-align: center;
  gap: 5px;
  display: flex;
  background-color: rgba(109, 110, 112, 0.05);
  padding: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: v.$secondaryGray;
  line-height: 16px;
  border-radius: 5px;

  svg {
      width: 12px;
      min-width: 12px;
  }
}