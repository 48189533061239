// import fonts
@font-face {
    font-family: 'Tajawal';
    src: url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-ExtraBold.woff2') format('woff2'),
        url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tajawal';
    src: url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Bold.woff2') format('woff2'),
        url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tajawal';
    src: url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Black.woff2') format('woff2'),
        url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tajawal';
    src: url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-ExtraLight.woff2') format('woff2'),
        url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tajawal';
    src: url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Medium.woff2') format('woff2'),
        url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tajawal';
    src: url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Regular.woff2') format('woff2'),
        url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tajawal';
    src: url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Light.woff2') format('woff2'),
        url('https://d22lbpcxo85heg.cloudfront.net/assets/app/media/fonts/tajawal_optimized/Tajawal-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// @import url(./bootstrap.min.css);
// Override default variables before the import
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #17a2b8;
$primary-bg: #3e8bc9;
$primary: #1b75bc;
$warning: #eca066;
$dark: #35405a;
$body-font-family: 'Tajawal', sans-serif;
$font-sans-serif: 'Tajawal', sans-serif;
$body-bg: #fff;
$font-family-sans-serif: 'Tajawal', sans-serif;
$line-height-base: 1.5;
$body-line-height: 1.5;
// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';

@import "~bootstrap/scss/functions";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/root";

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/spinners";

@import "~bootstrap/scss/utilities/api";

.collapse:not(.show) {
    transition: all .3s ease-in-out;
    visibility: hidden;
    height: 0px;
    opacity: 0;
}