@use 'styles/_variables.scss' as v;

.NavButton {
  height: 44px;
  background-color: v.$primaryBlue;
  border-radius: 23px;
  color: v.$white;
  border: none;
  transition: all .4s ease-in-out;
  justify-content: center;

  &:hover {
    background-color: #4383b5;
  }
}

@media (max-width: 991px) {
    .NavButton {
      background-color: v.$white;
      color: v.$black;
      flex: unset !important;
      justify-content: start !important;

      .loginButtonLink {
        justify-content: start !important;
        margin-left: 15px;
      }

      &:hover {
        background-color: transparent;
        color: v.$primaryBlue;
      }
    }
}