@use 'styles/_variables.scss' as v;

@mixin hideScroll {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    display: none;
    background-color: transparent;
  }
}

@mixin TitleSize20 {
  font-weight: 700;
  font-size: 20px;
  color: v.$primaryBlue;

  @media (max-width: 425px) {
    font-size: 16px;
  }
}

@mixin TitleSize16 {
  font-weight: 700;
  font-size: 16px;
  color: v.$primaryBlue;
  margin-bottom: 0px;

  @media (max-width: 425px) {
    font-size: 14px;
  }
}

@mixin TitleSize16withoutResponsive {
  font-weight: 700;
  font-size: 16px;
  color: v.$primaryBlue;
  margin-bottom: 0px;
}

@mixin OnlineDots {
  .onlineDote {
    width: 14px;
    height: 14px;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 100rem;
    position: absolute;
    right: 16px;
    bottom: 3px;

    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 7px;
      right: 7px;
      transform: translate(50%, -50%);
    }
  }

  .onlineSuccess {
    color: #008000;

    .onlineDote {
      &::before {
        background-color: #008000;
      }
    }
  }

  .onlineDanger {
    color: #dd0606;

    .onlineDote {
      &::before {
        background-color: #dd0606;
      }
    }
  }
}

@mixin buttonSendConsultation {
  border-radius: 50px;
  padding: .475rem .75rem;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
  font-weight: 700;
  font-size: 14px;

  span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: v.$primaryLightGreen;
  }
}

@mixin textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin textEllipsis_2Line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flipImage {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@mixin unFlipImage {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}