.btn-warning {
    --bs-btn-color: #fff;
    --bs-nav-link-color: #fff;
}

.form-control:focus {
    box-shadow: none;
    border-color: #1b75bc;
}

.alert-warning {
    --bs-alert-color: #856404;
    --bs-alert-bg: #fff3cd;
    --bs-alert-border-color: #fff3cd;
}

.list-unstyled {
    padding-right: 0;
}

[dir='rtl'] .text-end {
    text-align: left !important;
}

[dir='rtl'] .form-check {
    padding-right: 1.5em;
}

[dir='rtl'] .form-check .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: auto;
}

.shimmer {
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1000px 100%;
    animation: shimmer 2.2s linear infinite forwards;
    width: 100%;
    /* display: none; */
}

.withPadding {
    padding: 10px 0px;
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

.btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(27,117,188,.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    box-sizing: initial;
    height: 1em;
    opacity: 1;
    padding: 0.25em;
    width: 1em;
}

@media (min-width: 1200px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1080px;
    }
}

* {
    scroll-behavior: smooth;
    scroll-padding-top: 5.5rem;
}