@use 'styles/_variables.scss' as v;
@use 'styles/_mixins.scss' as m;

.loginContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/images/AuthImages/authBackgroundImg.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 80px 0 50px;
    position: relative;

    .mainContent {
        background: linear-gradient(98.92deg, v.$primaryBlue 11.18%, rgba(0, 173, 238, 0) 134.1%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 800;
        line-height: 40px;
        font-size: 36px;
    }

    .downloadApp {
        font-size: 14px;
        font-weight: 700;
        color: v.$black;
    }

    .loginCard {
        max-width: 450px;
        width: 95%;
        margin-top: 40px;
        --bs-card-border-radius: 0.5rem;
        --bs-card-spacer-y: 2.65rem;
        --bs-card-spacer-x: 1.5rem;
        font-size: 12px;
        --bs-card-color: #35405a;
        border-radius: 20px;
        box-shadow: 0px 0px 100px 20px rgba(0, 0, 0, 0.10);
        overflow: hidden;
        border: none;

        label {
            font-size: 12px;
            margin-bottom: 0.3rem;
        }

        .card-body {
            padding-top: 1.5rem;
            padding-bottom: 1rem;
        }
    }

    &::before {
        content: '';
        position: absolute;
        background: url(../../assets/images/bg-login.png);
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: auto;
        z-index: 0;
        width: 120px;
        height: 240px;
        bottom: 0;
        left: 0;
    }
}

@media (max-width: 991px) {
    .loginContainer {
        padding: 60px 0 50px;

        .loginCard {
            width: 100%;
            max-width: 100%;
            margin-top: 0px;

            .card-body {
                padding-left: .5rem;
                padding-right: .5rem;
            }
        }
    }   
}


[dir="rtl"] {
    .loginContainer .mainContent {
        line-height: 55px;
    }
}